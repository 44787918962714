import axios from "axios";
import useAppSelector from "hooks/useAppSelector";
import { useMutation } from "@tanstack/react-query";
import { useUpdateCart } from "actions/useUpdateCart";
import { getOswUrl } from "config/configUtils";

type Props = {
  transactionId: string;
  appointmentId: number;
};

export const useDeleteAgencyRefundOSW = () => {
  const sessionId = useAppSelector((state) => state.authentication.sessionId);
  const { updateCart, loading } = useUpdateCart();

  const { mutateAsync, isPending, error } = useMutation({
    mutationFn: async ({ transactionId, appointmentId }: Props) => {
      const URL = `${getOswUrl()}/api/payments/agency/refund/${transactionId}/${appointmentId}`;

      await axios.delete(URL, {
        headers: {
          SessionId: sessionId,
        },
      });
    },
    onSuccess: () => {
      updateCart();
    },
  });

  return {
    deleteAgencyRefundOSW: mutateAsync,
    deleteAgencyRefundOSWIsLoading: isPending || loading,
    deleteAgencyRefundOSWError: error,
  };
};
