import { useQuery, UseQueryResult } from "@tanstack/react-query";
import axios from "axios";
import { getOswUrl, getShipCodeByName } from "config/configUtils";
import useAppSelector from "hooks/useAppSelector";
import { IOneSpaWorldListData } from "interfaces/OSW/IOneSpaWorldList";
import { getCruisesInfo } from "utils/cruiseUtils";

type Props = {
  date: string;
  sort: string;
  categoryIds: string;
};

export const useGetSpaTreatmentsList = ({
  date,
  sort = "asc",
  categoryIds = "",
}: Props) => {
  const sessionId = useAppSelector((state) => state.authentication.sessionId);
  const cruiseDetails = useAppSelector((state) => state.cruises.cruiseDetails);
  const cruisesInfo = getCruisesInfo(cruiseDetails);

  const URL = `${getOswUrl()}/api/ships/${getShipCodeByName({
    shipName: cruisesInfo.shipName,
  })}/spa-treatment/by-date/${date}?categoryIds=${categoryIds}&page=1&size=20&sort=price,${sort}`;

  const { data, isLoading }: UseQueryResult<any, Error> = useQuery({
    queryKey: ["useGetSpaTreatmentsList", date, sort, categoryIds],
    queryFn: async () => {
      try {
        const response = await axios.get(URL, {
          headers: {
            SessionId: sessionId,
          },
        });

        return response.data;
      } catch (error: any | Error) {
        console.error(
          error?.response?.data?.message ||
            "An error occurred while fetching data."
        );
      }
    },
    refetchOnWindowFocus: false,
  });

  return {
    spaTreatmentsData: data as IOneSpaWorldListData,
    spaTreatmentsDataIsLoading: isLoading,
  };
};
