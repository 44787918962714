import { FC, useCallback, useEffect, useState } from "react";
import browserHistory from "utils/history";
import ActivityDuration from "components/molecules/ActivityDuration";
import ExcursionAttributes from "components/ExcursionAttributes";
import PlannerContainer from "components/PlannerContainer";
import Modal from "components/modals/Modal/Modal";
import ModalGallery from "components/modals/ModalGallery";
import { formatPortDate, formatTimeHM } from "utils/dateTimeUtils";
import ImportantInformationEntry from "components/molecules/ImportantInformationEntry";
import BookedPassengersList from "components/BookedPassengersList";
import { imagePath } from "utils/imageUtils";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import If from "components/molecules/IF";
import SoldOutBox from "components/molecules/SoldOutBox";
import GalleryToggleButton from "components/GalleryToggleButton";
import SvgIcon from "components/atoms/SvgIcon";
import useAppSelector from "hooks/useAppSelector";
import { useGetExcursionDetails } from "../hooks/useGetExcursionDetails";
import Loader from "components/atoms/Loader";
import MusementContentHeaderImage from "./MusementContentHeaderImage";
import MusementAddToPlannerForm from "./MusementAddToPlannerForm";
import { useGetExcursionAvailabilityFromStoreByExcursionId } from "../hooks/useGetExcursionAvailabilityFromStoreByExcursionId";
import { useGetMusementMedia } from "../hooks/useGetMusementMedia";

import ModalFooter from "components/modals/ModalFooter";

import NotificationModal from "components/modals/NotificationModal";
import MusementPriceInfoBox from "./MusementPriceInfoBox/MusementPriceInfoBox";
import { useGetExcursionProductsAgeRanges } from "../hooks/useGetExcursionProductsAgeRanges";
import { IItineraryExcursion } from "interfaces/Musement/IItineraryExcursion";
import { IItinerary } from "interfaces/ReduxState/IItinerary";
import { RouteComponentProps } from "react-router-dom";
import usePostExcursionsSlots from "../hooks/usePostExcursionsSlots";
import { PriceProps } from "interfaces/Musement/IExcursionProduct";

const MusementExcursionDetail: FC<
  RouteComponentProps<{
    itineraryId: string;
    modal: string;
    activityId: string;
    excursionId: string;
  }>
> = ({ match }) => {
  const itinerary = useAppSelector((state) =>
    state.itinerary.find(
      (itinerary) => itinerary.id === match.params.itineraryId
    )
  );

  const cart = useAppSelector((state) => state.cart.excursions);

  const {
    data: excursion,
    loading,
    getExcursionDetails,
  } = useGetExcursionDetails();

  const itineraryExcursion = itinerary?.excursions?.find(
    (itineraryExcursion) => itineraryExcursion?.uuid === excursion?.uuid
  );

  const { adultAgeRange, childAgeRange, babyAgeRange } =
    useGetExcursionProductsAgeRanges({
      products: itineraryExcursion?.products,
    });

  const { fetchExcursionsSlots, excursionsSlots, excursionsSlotsIsLoading } =
    usePostExcursionsSlots();
  const { getMusementMedia, data: excursionMedia } = useGetMusementMedia();

  const [showPassengerModal, setShowPassengerModal] = useState(
    match.params.modal === "passengers"
  );

  const [excursionFromItinerary, setExcursionFromItinerary] =
    useState<IItineraryExcursion>();

  const [showImagesModal, setShowImagesModal] = useState(false);

  const [notificationModalData, setNotificationModalData] = useState<
    | {
        shouldShow: boolean;
        img: string;
        message: string;
      }
    | undefined
  >(undefined);
  const [prices, setPrices] = useState<PriceProps>({
    adult: null,
    children: null,
  });

  const { totalAvailability } =
    useGetExcursionAvailabilityFromStoreByExcursionId({
      products: itineraryExcursion?.products,
    });

  useEffect(() => {
    if (itinerary?.date && match.params.activityId) {
      fetchExcursionsSlots({
        activityUuids: [match.params.activityId],
        date: itinerary.date,
      });
    }
  }, [fetchExcursionsSlots, match.params.activityId, itinerary?.date]);

  useEffect(() => {
    if (excursionsSlots && match.params.activityId) {
      setPrices(excursionsSlots[match.params.activityId].prices);
    }
  }, [match.params.activityId, excursionsSlots]);

  useEffect(() => {
    getExcursionDetails({ activityId: match.params.activityId });
  }, [getExcursionDetails, match.params.activityId]);

  useEffect(() => {
    if (excursion?.uuid) {
      getMusementMedia({ activityUuid: excursion.uuid });
    }
  }, [excursion, getMusementMedia]);

  useEffect(() => {
    const excursionFromItinerary = itinerary?.excursions?.find(
      (itineraryExcursion) => itineraryExcursion.uuid === excursion?.uuid
    );

    setExcursionFromItinerary(excursionFromItinerary);
  }, [excursion, itinerary?.excursions]);

  const togglePassengerModal = () => {
    setShowPassengerModal(!showPassengerModal);
  };

  const toggleImagesModal = () => {
    setShowImagesModal(!showImagesModal);
  };

  const handleShowNotificationModal = ({
    img,
    message,
  }: {
    img: string;
    message: string;
  }) => {
    setShowPassengerModal(false);
    setNotificationModalData({ shouldShow: true, img, message });
  };

  const handleCloseNotificationModal = async () => {
    setNotificationModalData(undefined);
  };

  const buttonText =
    cart &&
    cart[match.params.itineraryId] &&
    cart[match.params.itineraryId][match.params.excursionId]
      ? "added to planner"
      : "add to planner";

  const excursionAttributes = excursionFromItinerary?.taxonomies?.map(
    (taxonomy) => taxonomy.label
  );

  const getExcursionFact = useCallback((itinerary: IItinerary) => {
    const factOne = `Day ${
      itinerary.dayOfCruise
    } of your itinerary (${formatPortDate(itinerary.date)})`;
    const factTwo = `From ${itinerary.port.name.defaultValue?.toUpperCase()} where the ship is in port: ${formatTimeHM(
      itinerary.arrival,
      "Previous day"
    )} - ${formatTimeHM(itinerary.departure, "Next day")}`;
    const excursionFacts = [factOne, factTwo];
    return excursionFacts;
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <PlannerContainer />

      <div className="container mt-4 mb-8 mx-auto">
        <button
          onClick={browserHistory.goBack}
          className="text-blue cursor-pointer print-hidden flex items-center hover:underline"
        >
          <SvgIcon name="chevron-left" className="w-4 h-4 inline" /> Back
        </button>

        <div className="print-text-center">
          <h1 className="uppercase">{excursion?.title}</h1>
        </div>

        <div className="print-text-center my-4 print-my-0">
          <MusementContentHeaderImage
            altText={excursion?.title + " - header image"}
            resourceId={excursion?.cover_image_url}
          >
            <GalleryToggleButton
              toggleGallery={
                excursionMedia && excursionMedia?.length > 1
                  ? toggleImagesModal
                  : undefined
              }
            />
          </MusementContentHeaderImage>
        </div>

        <div className="print-hidden">
          <ModalGallery show={showImagesModal} onClose={toggleImagesModal}>
            <ImageGallery
              items={excursionMedia}
              lazyLoad={true}
              showIndex={true}
              showPlayButton={false}
            />
          </ModalGallery>
        </div>

        <div className="sm:flex sm:flex-row-reverse my-8 print-flex print-flex-row-reverse">
          {/*right container - pricebox and key facts*/}
          <div className="sm:w-1/2 md:w-1/3 xl:w-1/4 print-w-1-4">
            {excursionsSlotsIsLoading ? (
              <Loader />
            ) : (
              <If test={totalAvailability > 0}>
                {/* show sold out message or price box depending if excursion is available or not */}
                <MusementPriceInfoBox
                  classes="w-full text-blue-dark border border-blue-light p-4"
                  adultPrice={prices.adult}
                  childPrice={prices.children}
                  buttonText={buttonText}
                  _onClick={togglePassengerModal}
                  currentAvailability={totalAvailability}
                  childAgeRange={childAgeRange}
                />
              </If>
            )}

            <If test={totalAvailability === 0 || !totalAvailability}>
              <SoldOutBox />
            </If>

            <div className="mt-6">
              <div className="uppercase p-4 bg-blue-light text-blue-dark print-py-0">
                Key facts
              </div>
              <div className="bg-blue-lightest p-4 print-py-0">
                {excursionAttributes && (
                  <div className="pb-2 border-b print-border-0">
                    <ExcursionAttributes attributes={excursionAttributes} />
                  </div>
                )}

                {excursion?.duration_range.max && (
                  <div className="py-2 border-b print-border-0">
                    <ActivityDuration
                      duration={excursion.duration_range.max}
                      isISO8601
                    />
                  </div>
                )}

                {itinerary && (
                  <div className="pt-2">
                    <ExcursionAttributes
                      attributes={getExcursionFact(itinerary)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          {/*left container - about the excursion & excursion itinerary*/}
          <div className="mt-8 sm:mt-0 sm:w-1/2 md:w-2/3 xl:w-3/4 sm:pr-4 print-w-3-4">
            <div className="text-blue-dark mb-6">
              <h2>About the excursion</h2>
              <div className="text-grey-darker my-2">{excursion?.about}</div>
            </div>

            <div className="text-grey-darker my-4 px-2 sm:px-4">
              <ImportantInformationEntry
                title="Just so you know"
                content={excursion?.info}
                isContentInHtml={true}
              />
            </div>
          </div>
        </div>
      </div>

      {excursion &&
        itineraryExcursion &&
        itinerary &&
        !excursionsSlotsIsLoading && (
          <Modal
            show={showPassengerModal}
            onClose={togglePassengerModal}
            title={excursion.title.toUpperCase()}
          >
            <BookedPassengersList
              itineraryId={match.params.itineraryId}
              excursionId={match.params.activityId}
            />

            <MusementAddToPlannerForm
              itineraryId={match.params.itineraryId}
              adultPrice={prices.adult}
              childPrice={prices.children}
              adultAgeRange={adultAgeRange}
              childAgeRange={childAgeRange}
              babyAgeRange={babyAgeRange}
              itineraryDate={itinerary.date}
              products={itineraryExcursion.products}
              onCancel={togglePassengerModal}
              onSuccess={handleShowNotificationModal}
              onError={handleShowNotificationModal}
              startTime={itineraryExcursion.startTime}
              activityDuration={itineraryExcursion.duration_range.max}
            />
          </Modal>
        )}

      {notificationModalData && (
        <NotificationModal
          show={notificationModalData.shouldShow}
          onClose={handleCloseNotificationModal}
          title={"Excursion reservation"}
          message={notificationModalData.message}
          img={
            <img
              className="align-middle w-12 h-12 inline-block"
              alt="notification icon"
              src={imagePath(notificationModalData.img)}
            />
          }
          footer={
            <ModalFooter
              actionDisabled={false}
              actionButtonText="Go to planner"
              onActionClick={() => {
                browserHistory.push("/itinerary");
              }}
            />
          }
        />
      )}
    </div>
  );
};

export default MusementExcursionDetail;
