import {
  IExcursionPassenger,
  IPassenger,
  IPersonType,
} from "interfaces/Common/IPassenger";
import { IMusementActivitiesCartType } from "interfaces/Musement/IExcursionCartType";
import {
  addDuration,
  areEventsOverlapping,
  calculateYearsDifference,
  checkIfAgeInRange,
  formatDateSlashesToDashes,
  getAgeRangeInNumbers,
} from "utils/dateTimeUtils";

export const getPassengerType = (
  passengerDob: string | undefined,
  itineraryDate: string,
  childAgeRange: string,
  babyAgeRange: string
): IPersonType => {
  // if no dob than set passenger type to "Adult" by default
  if (!passengerDob) {
    return "Adult";
  }

  // number of years for passenger when she will be on a itinerary date (in the future)
  const yearsDiff = calculateYearsDifference(
    passengerDob,
    formatDateSlashesToDashes(itineraryDate)
  );

  // since CRUISE-2095 reported we found it that "infant product" object could be absolutely absent
  if (babyAgeRange) {
    const isBabyInAgeRangeLimits = checkIfAgeInRange(yearsDiff, babyAgeRange);

    if (yearsDiff && isBabyInAgeRangeLimits) {
      return "Baby";
    }
  }

  if (childAgeRange) {
    const isChildInAgeRangeLimits = checkIfAgeInRange(yearsDiff, childAgeRange);

    if (yearsDiff && isChildInAgeRangeLimits) {
      return "Child";
    }

    const { minAge } = getAgeRangeInNumbers(childAgeRange);
    const isChildYoungerThanMinRange = yearsDiff < minAge;

    if (yearsDiff && isChildYoungerThanMinRange) {
      return "Baby";
    }
  }

  // otherwise passanger is an Adult
  return "Adult";
};

export const findExcursionByPassengerId = (
  collection: Array<IMusementActivitiesCartType[]>,
  passengerId: string
) => {
  const foundExcursions: IMusementActivitiesCartType[] = [];

  collection.forEach((element: IMusementActivitiesCartType[]) => {
    element.forEach((excursion: IMusementActivitiesCartType) => {
      excursion?.persons.forEach((person: IExcursionPassenger) => {
        if (person.passengerId === passengerId) {
          foundExcursions.push(excursion);
        }
      });
    });
  });

  return foundExcursions;
};

export const checkIfPassengerActivitiesOverlapping = (
  excrusionsList: IMusementActivitiesCartType[],
  startTime: string,
  activityDuration: string
) => {
  let isOverlapping = false;

  excrusionsList.find((excursion: IMusementActivitiesCartType) => {
    const endTime = addDuration(startTime, activityDuration);
    const excursionEndTime = addDuration(
      excursion.product.scheduledTime,
      excursion.durationRange
    );

    isOverlapping = areEventsOverlapping(
      { startTime: startTime, endTime: endTime },
      {
        startTime: excursion.product.scheduledTime,
        endTime: excursionEndTime,
      }
    );

    return isOverlapping;
  });

  return isOverlapping;
};

/**
 * Checks if the passenger's age is greater than the specified maximum age.
 *
 * @param {IPassenger} passenger - The passenger object containing date of birth (dob).
 * @param {number} maxAge - The maximum age allowed in the age range.
 * @param {string} itineraryDate - The date of the event or trip in the format "DD/MM/YYYY".
 * @returns {boolean} - Returns `true` if the passenger's age is greater than `maxAge`, otherwise `false`.
 *
 * @example
 * const passenger = { dob: "2021-08-31" };
 * const result = isOlderThanAgeRange(passenger, 1, "31/08/2023");
 * // result is true (passenger is 2 years old, maxAge is 1)
 */
export const isOlderThanAgeRange = (
  passenger: IPassenger,
  maxAge: number,
  itineraryDate: string
) => {
  return (
    calculateYearsDifference(
      passenger.dob,
      formatDateSlashesToDashes(itineraryDate)
    ) > maxAge
  );
};

/**
 * Checks if the passenger's age is less than the specified minimum age.
 *
 * @param {IPassenger} passenger - The passenger object containing date of birth (dob).
 * @param {number} minAge - The minimum age required in the age range.
 * @param {string} itineraryDate - The date of the event or trip in the format "DD/MM/YYYY".
 * @returns {boolean} - Returns `true` if the passenger's age is less than `minAge`, otherwise `false`.
 *
 * @example
 * const passenger = { dob: "2022-08-31" };
 * const result = isYoungerThanAgeRange(passenger, 2, "31/08/2023");
 * // result is true (passenger is 1 year old, minAge is 2)
 */
export const isYoungerThanAgeRange = (
  passenger: IPassenger,
  minAge: number,
  itineraryDate: string
) => {
  return (
    calculateYearsDifference(
      passenger.dob,
      formatDateSlashesToDashes(itineraryDate)
    ) < minAge
  );
};

/**
 * Determines if a young passenger falls within a given age range.
 *
 * @param {IPassenger} passenger - The passenger object containing date of birth (dob).
 * @param {string} ageRange - The age range in the format "(minAge-maxAge)" (e.g., "(2-5)").
 * @param {string} itineraryDate - The date of the event or trip in the format "DD/MM/YYYY".
 * @returns {boolean} - Returns `true` if the passenger's age falls within the provided age range, otherwise `false`.
 *                      Returns `false` if no age range is provided.
 *
 * @example
 * const passenger = { dob: "2020-08-31" };
 * const ageRange = "(2-5)";
 * const result = checkIfYoungPassengerIsEligible(passenger, ageRange, "31/08/2023");
 * // result is true (passenger is 3 years old, and falls within the age range 2-5)
 */
export const checkIfYoungPassengerIsEligible = (
  passenger: IPassenger,
  ageRange: string,
  itineraryDate: string
) => {
  if (!ageRange) {
    return false; // If no age range is provided, the passenger is not eligible
  }

  const { minAge, maxAge } = getAgeRangeInNumbers(ageRange);
  const isYounger = isYoungerThanAgeRange(passenger, minAge, itineraryDate);
  const isOlder = isOlderThanAgeRange(passenger, maxAge, itineraryDate);

  // Return true if the passenger falls within the age range
  return !isYounger && !isOlder;
};
