import Checkbox from "components/Checkbox";

export interface CheckboxListItemProps {
  id: string;
  name: string;
  selected: boolean;
}

interface CheckboxListFilterProps {
  list: CheckboxListItemProps[];
  handleCheckboxChange: (item: CheckboxListItemProps) => void;
}

export const CheckboxListFilter = ({
  list,
  handleCheckboxChange,
}: CheckboxListFilterProps) => {
  return (
    <div>
      <ul className="list-reset flex flex-wrap">
        {list.map((item) => (
          <li className="my-2 h-6 w-full sm:w-1/3" key={"list-item-" + item.id}>
            <Checkbox
              label={item.name}
              checked={item.selected}
              handleCheckboxChange={() => handleCheckboxChange(item)}
              key={"checkbox-" + item.id}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};
